const Profile = ({ active }) => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8457 2.09417C12.8616 1.10917 11.5007 0.5 9.99825 0.5C8.49575 0.5 7.13491 1.10917 6.15075 2.09417C5.16575 3.07833 4.55658 4.43917 4.55658 5.94167C4.55658 7.44417 5.16575 8.805 6.15075 9.78917C7.13491 10.7742 8.49575 11.3833 9.99825 11.3833C11.5007 11.3833 12.8616 10.7742 13.8457 9.78917C14.8307 8.805 15.4399 7.44417 15.4399 5.94167C15.4399 4.43917 14.8307 3.07833 13.8457 2.09417ZM9.99825 2.16667C11.0407 2.16667 11.9849 2.58917 12.6674 3.2725C13.3507 3.955 13.7732 4.89917 13.7732 5.94167C13.7732 6.98417 13.3507 7.92833 12.6674 8.61083C11.9849 9.29417 11.0407 9.71667 9.99825 9.71667C8.95575 9.71667 8.01158 9.29417 7.32908 8.61083C6.64575 7.92833 6.22325 6.98417 6.22325 5.94167C6.22325 4.89917 6.64575 3.955 7.32908 3.2725C8.01158 2.58917 8.95575 2.16667 9.99825 2.16667ZM2.49492 20.5001H9.99824H17.5016C18.6616 20.5001 19.4057 20.0884 19.7691 19.4293C20.0332 18.9484 20.0632 18.3776 19.8782 17.7701C19.7274 17.2743 19.4249 16.7443 18.9857 16.2218C17.4999 14.4551 14.2691 12.6093 9.99824 12.6093C5.72741 12.6093 2.49659 14.4551 1.01076 16.2218C0.571589 16.7443 0.268257 17.2743 0.118258 17.7701C-0.0675752 18.3776 -0.0367422 18.9484 0.227424 19.4293C0.590757 20.0884 1.33492 20.5001 2.49492 20.5001ZM17.5016 18.8334H2.49492C2.01325 18.8334 1.75575 18.7543 1.68575 18.6284C1.64575 18.5551 1.65409 18.4234 1.70659 18.2518C1.79325 17.9668 1.98742 17.6376 2.27992 17.2901C3.51992 15.8159 6.27741 14.2759 9.99824 14.2759C13.7191 14.2759 16.4766 15.8159 17.7166 17.2901C18.0091 17.6376 18.2032 17.9668 18.2899 18.2518C18.3424 18.4243 18.3507 18.5551 18.3107 18.6284C18.2407 18.7543 17.9824 18.8334 17.5016 18.8334Z"
                fill={active ? '#FAD810' : '#B9B9B9'}
            />
        </svg>
    )
}

export default Profile
