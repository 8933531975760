import BukaAppIcon from '../Footer/BottomNavigation/icons/BukaApp'
import {
    NavItem as FooterNavItem,
    Title as FooterTitle,
} from '../Footer/BottomNavigation/styles'
import {
    NavItem as SidebarNavItem,
    Title as SidebarTitle,
} from '../Layouts/sidebarStyle'
import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import { EventTracking } from '@/helpers/eventTracking'
import { _activeNav } from '@/store/selectors/home'
import * as playerSelectors from '@/store/selectors/player'
import { NAVTYPES } from '@/store/types/home'
import { APPSFLYER_ONELINK_WEB_TO_APP_BUKA_URL } from '@/utils/constants'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { isIOS, isIPad13, isMacOs } from 'react-device-detect'
import { useSelector } from 'react-redux'

export const BukaApp = ({ isSidebar }) => {
    const NavItem = isSidebar ? SidebarNavItem : FooterNavItem
    const Title = isSidebar ? SidebarTitle : FooterTitle

    const history = useRouter()
    // const dispatch = useDispatch()

    const currentPlayingCatalog = useSelector(
        playerSelectors._currentPlayingCatalog
    )
    const currentContentId = useSelector(playerSelectors._currentContentId)

    const catalogData = {} // props?.catalogDetails
    const contentData = {} //props?.contentDetails

    const showPlayer = useSelector(playerSelectors._showPlayer)
    const toggleScreen = useSelector(playerSelectors._toggleScreen)
    const playlist = useSelector(playerSelectors._playlist)
    const trackCount = useSelector(playerSelectors._trackCount)

    const { data: session } = useSession()

    const activeNav = useSelector(_activeNav)

    const isNavActive = (currentNav) =>
        activeNav === currentNav ? true : false

    const isTopNavVisible =
        history.pathname === '/' ||
        history.pathname === '/podcast' ||
        history.pathname === '/radio' ||
        history.pathname === '/audiobook' ||
        history.pathname === '/audioseries'

    const bukaAppHandler = () => {
        const urlParams = history.asPath.slice(1).split('/')
        let DYNAMIC_APPSFLYER_URL = window.AF_SMART_SCRIPT_RESULT1?.clickURL

        let WEB_TO_APP_LINK = APPSFLYER_ONELINK_WEB_TO_APP_BUKA_URL

        if (urlParams.length) {
            let deep_link_sub1 = urlParams[0]
            let deepLink =
                urlParams[1] ||
                history.query.contentId ||
                history.query.catalogId

            // replacing ? with & as its coming from app
            let deep_link_value = deepLink?.replace('?', '&')

            if (currentContentId) {
                deep_link_sub1 = 'content'
                deep_link_value = currentContentId
            }
            DYNAMIC_APPSFLYER_URL = `${DYNAMIC_APPSFLYER_URL}&deep_link_sub1=${deep_link_sub1}&deep_link_value=${deep_link_value}`
            WEB_TO_APP_LINK = `${WEB_TO_APP_LINK}?deep_link_sub1=${deep_link_sub1}&deep_link_value=${deep_link_value}`
        }

        let firebaseEventParams = {}
        let amplitudeEventParams = {}
        if (urlParams[0] === 'content' && !toggleScreen) {
            firebaseEventParams = {
                catalogId: contentData?.catalog?.id,
                catalogTitle: contentData?.catalog?.title,
                contentId: contentData?.id,
                contentTitle: contentData?.title,
                vertical: contentData?.catalog?.type,
                source: 'content page',
            }

            amplitudeEventParams = {
                'catalog id': contentData?.catalog?.id,
                'catalog title': contentData?.catalog?.title,
                'content id': contentData?.id,
                'content title': contentData?.title,
                vertical: contentData?.catalog?.type,
                source: 'content page',
            }
        }

        if (urlParams[0] === 'catalog' && !toggleScreen) {
            firebaseEventParams = {
                catalogId: catalogData?.id,
                catalogTitle: catalogData?.title,
                vertical: catalogData?.type,
                source: 'catalog page',
            }
            amplitudeEventParams = {
                'catalog id': catalogData?.id,
                'catalog title': catalogData?.title,
                vertical: catalogData?.type,
                source: 'catalog page',
            }
        }
        if (showPlayer && toggleScreen) {
            firebaseEventParams = {
                catalogId:
                    playlist[trackCount]?.catalog?.id ||
                    currentPlayingCatalog?.id,
                catalogTitle:
                    playlist[trackCount]?.catalog?.title?.substring(0, 100) ||
                    currentPlayingCatalog?.title?.substring(0, 100),
                contentId: playlist[trackCount]?.id,
                contentTitle: playlist[trackCount]?.title?.substring(0, 100),
                vertical:
                    playlist[trackCount]?.catalog?.type ||
                    currentPlayingCatalog?.type,
                source: 'media player',
            }

            amplitudeEventParams = {
                'catalog id':
                    playlist[trackCount]?.catalog?.id ||
                    currentPlayingCatalog?.id,
                'catalog title':
                    playlist[trackCount]?.catalog?.title?.substring(0, 100) ||
                    currentPlayingCatalog?.title?.substring(0, 100),
                'content id': playlist[trackCount]?.id,
                'content title': playlist[trackCount]?.title?.substring(0, 100),
                vertical:
                    playlist[trackCount]?.catalog?.type ||
                    currentPlayingCatalog?.type,
                source: 'media player',
            }
        }
        if (isTopNavVisible && !toggleScreen) {
            firebaseEventParams = {
                vertical:
                    history.pathname === '/' ? 'home page' : 'vertical page',
            }

            amplitudeEventParams = {
                vertical:
                    history.pathname === '/' ? 'home page' : 'vertical page',
            }
        }
        if (isMacOs || isIPad13 || isIOS) {
            firebaseEventParams.destination = 'download'
            amplitudeEventParams.destination = 'download'

            let deepUrl = DYNAMIC_APPSFLYER_URL || WEB_TO_APP_LINK
            window.location.href = deepUrl
        } else {
            firebaseEventParams.destination = 'open app'
            amplitudeEventParams.destination = 'open app'

            let deepUrl = DYNAMIC_APPSFLYER_URL || WEB_TO_APP_LINK
            window.location.href = deepUrl
        }
        if (session?.token) {
            AmplitudeEventTracking('web to app', amplitudeEventParams, session)
            EventTracking('web_to_app', firebaseEventParams, session)
        }
    }

    return (
        <a
            onClick={bukaAppHandler}
            className={isNavActive(NAVTYPES.BUKA_APP) ? `active` : ''}
        >
            <NavItem>
                <BukaAppIcon
                    active={isNavActive(NAVTYPES.BUKA_APP)}
                    isSidebar={isSidebar}
                />
                <Title active={isNavActive(NAVTYPES.BUKA_APP)}>
                    {NAVTYPES.BUKA_APP}
                </Title>
            </NavItem>
        </a>
    )
}
