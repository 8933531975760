import BottomNavigation from './BottomNavigation'
import {
    Container,
    DownloadAppButtonContainer,
    DownloadAppButtonText,
    DownloadAppText,
    FooterContainer,
} from './styles'
import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import { EventTracking } from '@/helpers/eventTracking'
import { APPSFLYER_ONELINK_WEB_TO_APP_URL } from '@/utils/constants'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { isIOS, isMacOs, isIPad13 } from 'react-device-detect'
import * as playerSelectors from '@/store/selectors/player'
import { useSelector } from 'react-redux'
const Footer = () => {
    const router = useRouter()
    const showPlayer = useSelector(playerSelectors._showPlayer)
    const { data: session } = useSession()

    return (
        <FooterContainer>
            <Container showPlayer={showPlayer}>
                <DownloadAppText>
                    Buka semua fitur dengan download aplikasi Noice
                </DownloadAppText>
                <DownloadAppButtonContainer
                    onClick={() => {
                        const urlParams = router.asPath.slice(1).split('/')

                        let DYNAMIC_APPSFLYER_URL =
                            window.AF_SMART_SCRIPT_RESULT?.clickURL

                        let WEB_TO_APP_LINK = APPSFLYER_ONELINK_WEB_TO_APP_URL

                        if (urlParams.length) {
                            DYNAMIC_APPSFLYER_URL = `${DYNAMIC_APPSFLYER_URL}&deep_link_sub1=${urlParams[0]
                                }&deep_link_value=${urlParams[1] ||
                                router.query.contentId ||
                                router.query.catalogId
                                }`
                            WEB_TO_APP_LINK = `${WEB_TO_APP_LINK}&deep_link_sub1=${urlParams[0]
                                }&deep_link_value=${urlParams[1] ||
                                router.query.contentId ||
                                router.query.catalogId
                                }`
                        }

                        let eventParams = {}

                        if (isMacOs || isIPad13 || isIOS) {
                            eventParams = { destination: 'download' }

                            // window.location.href =
                            //     'https://apps.apple.com/us/app/noice-podcast-radio-musik/id1484372791'
                            window.location.href =
                                DYNAMIC_APPSFLYER_URL || WEB_TO_APP_LINK
                        } else {
                            eventParams = {
                                destination: 'open app',
                            }

                            // const { host, pathname } = window.location

                            // const deepUrl = `intent://${
                            //     host + pathname
                            // }#Intent;scheme=https;package=noice.app;end`

                            const deepUrl =
                                DYNAMIC_APPSFLYER_URL || WEB_TO_APP_LINK

                            window.location.href = deepUrl
                        }

                        if (session?.token) {
                            AmplitudeEventTracking('web to app', '', session)
                            EventTracking('web_to_app', eventParams, session)
                        }
                    }}
                >
                    <DownloadAppButtonText>Kunjungi App</DownloadAppButtonText>
                </DownloadAppButtonContainer>
            </Container>
            <BottomNavigation />
        </FooterContainer>
    )
}

export default Footer
