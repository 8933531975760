const BukaAppIcon = ({ isSidebar }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
        >
            <path
                d="M12.5 2.5C13.0523 2.5 13.5 2.94772 13.5 3.5L13.5 14.984L17.7049 10.806C18.1156 10.398 18.7814 10.398 19.192 10.806C19.6027 11.214 19.6027 11.8756 19.192 12.2836L13.2436 18.194C12.8329 18.602 12.1671 18.602 11.7564 18.194L5.80799 12.2836C5.39734 11.8756 5.39734 11.214 5.80799 10.806C6.21865 10.398 6.88445 10.398 7.2951 10.806L11.5 14.984L11.5 3.5C11.5 2.94772 11.9477 2.5 12.5 2.5Z"
                fill={isSidebar ? '#888888' : '#B9B9B9'}
            />
            <path
                d="M5.5 20.5C4.94772 20.5 4.5 20.9477 4.5 21.5C4.5 22.0523 4.94772 22.5 5.5 22.5H19.5C20.0523 22.5 20.5 22.0523 20.5 21.5C20.5 20.9477 20.0523 20.5 19.5 20.5H5.5Z"
                fill={isSidebar ? '#888888' : '#B9B9B9'}
            />
        </svg>
    )
}
export default BukaAppIcon
