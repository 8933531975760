import { RowFlex, ColumnFlex, FlexCentered } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${RowFlex}
    justify-content: space-around;
    padding: 10px 0;
    background-color: #141414;
    @media (min-width: 991px) {
        display: none;
    }
`
export const NavItem = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    cursor: pointer;
`
export const Title = styled.span`
    font-size: 12px;
    margin-top: 5px;
    color: ${({ active }) => (active ? '#FAD810' : '#B9B9B9')};
`
