import { ColumnFlex, FlexCentered, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const FooterContainer = styled.div`
    ${ColumnFlex}
    width: 100%;
    position: fixed;
    bottom: 0;
    // @media (min-width: 992px) {
    //     width: 700px;
    // }
    z-index: 3;
    @media (min-width: 991px) {
        display: none;
    }
`
export const Container = styled.div`
    ${RowFlex}
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #202020;
    z-index: 1;
    padding: 1rem;
    // display: ${({ showPlayer }) => showPlayer && 'none'};
    display: none;
`

export const DownloadAppText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
`

export const DownloadAppButtonContainer = styled.div`
    background: #fad810;
    border-radius: 4px;
    width: 34.16%;
    height: 52.94%;
    ${RowFlex}
    ${FlexCentered}
    cursor: pointer;
`

export const DownloadAppButtonText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #1e222c;
    padding: 10px;
`
